<template>
  <div class="section2">
    <div class="section_tabs">
      <ol class="ol_tabicons">
        <li
          :class="{
            on: currentActive === category.id,
          }"
          v-for="category in categories"
          :key="category.id"
          @click="setCurrentActive(category.id)"
        >
          <h3>{{ category.title }}</h3>
        </li>
      </ol>
      <div class="div_tabdivs">
        <ul style="position: relative; padding-top: 20px">
          <span class="ia" style="cursor:pointer;" @click="$router.push(moreLink)"> 查看更多 > </span>
          <ul>
            <li v-for="article in articles" :key="article.id">
              <p>
                <a target="_blank" :href="article.href" @click="$router.push({
                  path: '/portalDetails',
                  query: {
                    id: article.id,
                    activeCode: 'xinwendongtai',
                  }
                })">
                  {{ article.title }}
                </a>
              </p>
              <span class="sx-block">【 {{ article?.ctime?.split(' ')[0] ?? '' }} 】</span>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import portalApi from '../../../../lvya-common/src/api/eos/portal'
import { useGetCategories } from '../utils/useGetCategories'

export default {
  data() {
    return {
      currentActive: undefined,
      articles: [],
    }
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    categories() {
      return (
        this.nav
          ?.find((item) => item.code === 'xinwendongtai')
          ?.stypes?.map((nav) => ({
            id: nav.id,
            title: nav.title,
            path: nav.tzurl,
          })) || []
      )
    },
    moreLink() {
      return this.categories.find(item => item.id === this.currentActive)?.path || '/portal'
    },
  },
  methods: {
    async setCurrentActive(id) {
      this.currentActive = id
      let typeId = id

      // 获取改分类下是否有其他分类，有就取其第一个查询其下的文章列表
      const categories = await useGetCategories(id)
      console.log(categories, 'categories')
      if (categories.list.length) {
        typeId = categories.list[0].id
      }

      // getArticles
      this.getArticles(typeId)
    },

    async getArticles(typeId) {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: '1',
        pageSize: '4',
        typeId: typeId,
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      this.articles = resData.list
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.categories?.length) {
        this.setCurrentActive(this.categories[0].id)
      }
    }, 60)
  }
}
</script>

<style lang="less" scoped>
@import "../style/index.css";
@import "../style/global.css";
.section2,
.section3,
.section4,
.section5,
.section6 {
  margin-bottom: 1rem;
}
</style>
