<template>
  <div class="section1">
    <div class="div_entrance">
      <ul>
        <li v-for="(nav,index) in roles" :key="index">
          <a @click="$router.push(nav.path)">{{nav.title}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      roles: (state) => state.yzsyStore.roles,
    })
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
@import "../style/index.css";
</style>
