<template>
  <div v-if="portalSet?.ispc == 'Y'" @click="toUrl(portalSet.pcurl)">
    <div
      v-if="show"
      @mouseover="mouseover"
      @mouseout="mouseout"
      class="box"
      :style="{ top: top + 'px', left: left + 'px' }"
    >
      <span @click="close">关闭</span>
      <div>
        <img :src="portalSet.pcimg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import portalApi from '../../../../lvya-common/src/api/eos/portal'

export default {
  data() {
    return {
      portalSet: null,
      show: true, // 是否展现飘窗
      stepX: 1, // 水平方向的步长
      stepY: 1, // 垂直方向的步长
      timer: null, // 定时器
      maxTop: 0, // 最大的 top 值
      maxLeft: 0, // 最大的 left 值
      top: 0,
      left: 0,
    }
  },
  methods: {
    // 初始化飘窗规则
    init() {
      // 设置最大的top和left值：根元素可视区域宽高 - 飘窗的宽高 - 边距
      this.maxTop = document.documentElement.clientHeight - 150 - 20
      this.maxLeft = document.documentElement.clientWidth - 200 - 20
      // 设置 top 和 left 的初始值
      this.top = 0
      this.left = 0
      // 创建定时器前清除定时器，避免类似在 onresize 中调用 init() 时，产生多个定时器
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.move()
      }, 20)
      this.onresize()
    },
    // 移动函数
    move() {
      if (this.top >= this.maxTop || this.top < 0) {
        this.stepY = -this.stepY
      }
      if (this.left >= this.maxLeft || this.left < 0) {
        this.stepX = -this.stepX
      }
      this.top += this.stepY
      this.left += this.stepX
    },
    // 鼠标悬浮在飘窗时停止移动
    mouseover() {
      clearInterval(this.timer)
    },
    // 鼠标离开飘窗时恢复移动
    mouseout() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.move()
      }, 20)
    },
    // 关闭飘窗
    close() {
      clearInterval(this.timer)
      this.show = false
    },
    // 窗口大小调整时重置飘窗规则
    onresize() {
      const that = this
      window.onresize = function() {
        that.init()
      }
    },
    // 获取门户基础设置
    async getPortalSet() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )

      let resData = await portalApi.getSet({
        noSchoolId: true,
        schoolId: '7',
      })
      console.log({ resData }, 'portal set')
      this.portalSet = resData
    },
  },
  async mounted() {
    await this.getPortalSet()
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style lang="less" scoped>
.box {
  // background: #9cdf65;
  width: 200px;
  height: 150px;
  border-radius: 5px;
  position: fixed;
  text-align: left;
  color: #ffffff;
  top: 0;
  left: 0;
  z-index: 99999999;
  cursor: pointer;
  > span {
    text-align: right;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #94070a;
    font-weight: 600;
    cursor: pointer;
  }
  > div {
    img {
      max-width: 100%;
      max-height: 70%;
    }
  }
}
</style>
