<template>
  <div class="body_b">
    <!-- 通知公告 & 党建园地 -->
    <HomeNewsList />

    <div
      class=""
      :class="{
        [`section${3 + index}`]: true,
      }"
      v-for="(item, index) in nav"
      :key="item.id"
    >
      <dl class="home_list">
        <dt>
          <div class="div_tit">
            <h2>{{ item.title }}</h2>
            <!-- 栏目简介 -->
            <div v-html="item.bz"></div>
          </div>
        </dt>
        <dd>
          <!-- 该分类下sort为0的分类 -->
          <div class="div_img">
            <img :src="sections[index]?.bgimg || defaultBanner" alt="" />
            <div v-html="sections[index]?.bz"></div>
          </div>

          <div class="slide_nav">
            <ul>
              <li v-for="link in item.stypes" :key="link.id">
                <a @click="$router.push(link.tzurl)">{{ link.title }}</a>
              </li>
            </ul>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useGetCategories } from '../utils/useGetCategories'
import HomeNewsList from './HomeNewsList.vue'
import defaultBanner from '../images/home/section_banner3.jpg'
import portalApi from '../../../../lvya-common/src/api/eos/portal'
export default {
  components: { HomeNewsList },
  data() {
    return {
      section: {
        title: '',
        desc: '',
        cover: '',
      },
      // 栏目大图
      sections: [],
      defaultBanner,
    }
  },
  computed: {
    ...mapState({
      storeNav: (state) => state.yzsyStore.nav,
    }),
    nav() {
      return this.storeNav.filter(
        (item) =>
          item.code !== 'xinwendongtai' &&
          item.code !== 'home' &&
          item.code !== ''
      )
    },
  },
  methods: {
    async getSections() {
      for (let i = 0; i < this.nav.length; i++) {
        let res = await useGetCategories(this.nav[i].id)
        this.sections.push(res.list.find((item) => item.sort == 0) || {})
      }
    },
    async getaddAppCount() {
      let res = {
        noSchoolId: true,
        schoolId: '7',
      }
      await portalApi.addAppCount(res)
    },
  },
  mounted() {
    this.getaddAppCount()
    this.getSections()
  },
}
</script>

<style lang="less" scoped>
@import "../style/index.css";
@import "../style/global.css";
.section2,
.section3,
.section4,
.section5,
.section6 {
  margin-bottom: 1rem;
}
</style>
