<template>
  <div class="body_home section1">
    <!-- banner -->
    <!-- 文章列表 -->
    <div class="swiper-container swiper-container-homebanner">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(article, index) in articles"
          :key="index"
        >
          <a style="display: block; width: 100%; height: 100%">
            <img
              class="imgPc scale"
              :src="article.files"
              :alt="article.title"
            />
            <div class="banner_txt">
              <h3>{{ article.title }}</h3>
              <p class="line-clamp-1 w-half">{{ article.des }}</p>
            </div>
          </a>
        </div>
      </div>

      <div class="banner_icons"></div>
    </div>

    <!-- 入口 -->
    <HomeLeftAside />

    <!-- nextIcon -->
    <span class="nextIcon" @click="showContent"></span>
  </div>
</template>

<script>
import portalApi from '../../../../lvya-common/src/api/eos/portal'
import HomeLeftAside from './HomeLeftAside.vue'
import { mapState } from 'vuex'
export default {
  components: { HomeLeftAside },
  data() {
    return {
      portalSchoolInfo: null,
      articles: [],
    }
  },
  computed: {
    ...mapState({
      storeNav: (state) => state.yzsyStore.nav,
    }),
    homeId() {
      return this.storeNav.find((ele) => ele.code === 'home')?.id
    },
  },
  methods: {
    showContent() {
      let viewportHeight = window.innerHeight
      window.scrollTo({
        top: viewportHeight - 60,
        behavior: 'smooth',
      })
    },
    // 首页banner文章
    async getArticles() {
      this.portalSchoolInfo = JSON.parse(
        localStorage.getItem('portalSchoolInfo')
      )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: '1',
        pageSize: '10',
        typeId: this.homeId,
        isContent: 'Y',
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      this.articles = resData.list
    },
  },
  mounted() {
    setTimeout(async() => {
      await this.getArticles()
      new Swiper('.swiper-container', {
        loop: true,
        autoplay: true,
        delay: 5000,
        pauseOnMouseEnter: true,
        longSwipes: false,
        pagination: {
          el: '.banner_icons',
          clickable: true,
        },
      })
    }, 50)
  },
}
</script>

<style lang="less" scoped>
@import "../style/index.css";

.body_home {
  height: 100vh;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  scroll-snap-type: y mandatory;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.w-half {
  width: 50%;
}
</style>
