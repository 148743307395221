<template>
  <div>
    <HomeSection1 />
    <HomeMain />
    <!-- 飘窗 -->
    <FloatDialog />
  </div>
</template>

<script>
import HomeMain from './components/HomeMain.vue'
import HomeSection1 from './components/HomeSection1.vue'
import FloatDialog from './components/FloatDialog.vue'

export default {
  components: { HomeSection1, HomeMain, FloatDialog },
  data() {
    return {
      showContent: false
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
</style>
