var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section2"},[_c('div',{staticClass:"section_tabs"},[_c('ol',{staticClass:"ol_tabicons"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.id,class:{
          on: _vm.currentActive === category.id,
        },on:{"click":function($event){return _vm.setCurrentActive(category.id)}}},[_c('h3',[_vm._v(_vm._s(category.title))])])}),0),_c('div',{staticClass:"div_tabdivs"},[_c('ul',{staticStyle:{"position":"relative","padding-top":"20px"}},[_c('span',{staticClass:"ia",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(_vm.moreLink)}}},[_vm._v(" 查看更多 > ")]),_c('ul',_vm._l((_vm.articles),function(article){return _c('li',{key:article.id},[_c('p',[_c('a',{attrs:{"target":"_blank","href":article.href},on:{"click":function($event){return _vm.$router.push({
                path: '/portalDetails',
                query: {
                  id: article.id,
                  activeCode: 'xinwendongtai',
                }
              })}}},[_vm._v(" "+_vm._s(article.title)+" ")])]),_c('span',{staticClass:"sx-block"},[_vm._v("【 "+_vm._s(article?.ctime?.split(' ')[0] ?? '')+" 】")])])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }