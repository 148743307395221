import { get, post, url } from '../../service/ApiDecorator'

class Portal {
  // 页头页尾信息
  @url('/v2/schoolsite/api/:id/getSetInfoBySid')
  @post
  getSetInfoBySid() {}

  // 图片新闻
  @url('/v2/schoolsite/api/:id/getPicArts')
  @get
  getPicArts() {}

  // 轮播
  @url('/v2/schoolsite/api/:id/getPics')
  @get
  Banner() {}

  // 导航栏
  @url('/v2/schoolsite/api/:id/getTypes')
  @get
  getNav() {}

  // 学校新闻栏目,部门动态,校务公开,工作计划
  @url('/v2/schoolsite/api/:id/getArts')
  @get
  getArtsList() {}

  // 应用中心
  @url('/v2/schoolsite/api/:id/getTypes')
  @get
  Yycenter() {}

  // 友情链接
  @url('/v2/schoolsite/api/:id/getUrls')
  @get
  friendshipLinks() {}

  // 文章详情
  @url('/v2/schoolsite/api/:id/getArtInfo')
  @post
  getArtInfo() {}

  // 搜索
  @url('/v2/schoolsite/api/:id/getLikeArts')
  @post
  Search() {}

  // 查询指定分类下友情链接
  @url('/v2/schoolsite/arealink/getLinkListByTypeId')
  @get
  getLinkListByTypeId() {}

  // 局长信箱
  @url('/v2/app/shared/v1/getSharedDataByTaskId')
  @get
  getSharedDataByTaskId() {}

  // 友情链接类型列表（不分页）
  @url('/v2/schoolsite/manage/api/linkType/getLinkTypeList')
  @get
  getLinkTypeList() {}

  // 友情链接列表（不分页）-不带登录权限
  @url('/v2/schoolsite/manage/api/link/role/getLinkList')
  @get
  getLinkListNoRole() {}

  // 获取友情链接详情
  @url('/v2/schoolsite/manage/api/link/getLink')
  @get
  getLink() {}

  // 区块列表
  @url('/v2/schoolsite/manage/api/block/getBlocks')
  @get
  getBlocks() {}

  // 系统日志接入游客
  @url('/v2/schoolsite/api/index/addAppCount')
  @get
  addAppCount() {}

  // 访问量增加
  @url('/v2/schoolsite/api/index/addCount')
  @get
  addCount() {}

  // 轮播图列表-不带登录权限
  @url('/v2/schoolsite/manage/api/img/role/getImgs')
  @get
  getImgs() {}

  // 栏目列表（分页）-不带登录权限
  @url('/v2/schoolsite/manage/api/type/role/getTypeListByPage')
  @get
  getTypeListByPage() {}

  // 文章列表（分页）-不带登录权限
  @url('/v2/schoolsite/manage/api/art/role/getArtsByPage')
  @get
  getArtsByPage() {}

  // 获取学校设置信息-不带登录权限
  @url('/v2/schoolsite/manage/api/set/role/getSet')
  @get
  getSet() {}

  // 获取文章详情
  @url('/v2/schoolsite/manage/api/art/getArt')
  @get
  getArtsInfo() {}

  // 登陆流程相关接口-学校信息
  @url('/v2/auth/eos/getschool/byId')
  @post
  getById() {}

  // ***********************封面管理****************************
  // 获取设置信息-不带登录权限
  @url('/v2/schoolsite/manage/api/cover/set/role/getSet')
  @get
  getSetFm() {}
  // 轮播图列表-不带登录权限
  @url('/v2/schoolsite/manage/api/cover/img/role/getImgs')
  @get
  getImgsFm() {}
  // 封面地址列表（不分页）-不带登录权限
  @url('/v2/schoolsite/manage/api/cover/link/role/getLinkList')
  @get
  getLinkListFm() {}
  // 根据学校域名 获取学校信息
  @url('/v2/auth/eos/getschool/byDomain')
  @post
  getSchool() {}
}

export default new Portal()
